// import React from 'react';
// import Slider from 'react-slick';
// import './SectionFirst.css'; // Import the CSS file
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// const sliderSettings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   autoplay: true,
//   autoplaySpeed: 4000,
//   arrows: false,
//   pauseOnHover: true,
//   responsive: [
//     {
//       breakpoint: 768,
//       settings: {
//         slidesToShow: 1,
//       },
//     },
//   ],
// };

// export default function SectionFirst() {
//   const isMobile = window.innerWidth < 768;

//   const mobileImages = [
//     "https://github.com/aayushwellness/RCAssets/blob/main/SliderImages/slider%20banner%20-3%20-%20mobile%20size.jpg?raw=true",
//   ];

//   const desktopImages = [
//     "https://github.com/aayushwellness/RCAssets/blob/main/SliderImages/slider%20banner%20-3%20-desktop%20size.jpg?raw=true",
//   ];

//   const images = isMobile ? mobileImages : desktopImages;

//   return (
//     <>
//       {/* Slider */}
//       <div className="slider-container">
//         <Slider {...sliderSettings}>
//           {images.map((src, index) => (
//             <div key={index}>
//               <img
//                 loading="lazy"
//                 src={src}
//                 alt={`Slide ${index + 1}`}
//                 className="slider-image"
//               />
//             </div>
//           ))}
//         </Slider>
//       </div>
//     </>
//   );
// }








// Simple Image Temp


import React from 'react';
import './SectionFirst.css'; // Import the CSS file

export default function SectionFirst() {
  const isMobile = window.innerWidth < 768;

  const mobileImages = [
    "https://cdn.shopify.com/s/files/1/0653/9830/9053/files/Website_Banner_Design.jpg?v=1737625201",
  
  ];

  const desktopImages = [
   
    "https://cdn.shopify.com/s/files/1/0653/9830/9053/files/Website_Banner_Design.jpg?v=1737625201"
  ];

  const images = isMobile ? mobileImages : desktopImages;

  return (
    <div className="slider-container">
      <img
        loading="lazy"
        src={images[0]} // Show only the first image
        alt="Static slide"
        className="slider-image"
      />
    </div>
  );
}
